import React from "react";

import "./MessageBox.css";

const MessageBox = props => {
    let classes = "message-box mx-auto text-center";
    if (props.isError) {
        classes += " error";
    } else {
        classes += " success";
    }

    return (
        <div className={classes}>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <p className="mx-auto">{props.isError}</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <p className="mx-auto">{props.content}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageBox;
