class AuthService {
    setAccessToken = accessToken => {
        sessionStorage.accessToken = accessToken;
    };

    setUser = user => {
        sessionStorage.user = JSON.stringify(user);
    };

    getAccessToken = () => {
        return sessionStorage.accessToken;
    };

    removeSessionStorage = () => {
        this.removeAccessToken();
    };

    removeAccessToken = () => {
        delete sessionStorage.accessToken;
    };

    removeUser = () => {
        delete sessionStorage.user;
    };
}

const instance = new AuthService();
Object.freeze(instance);

export default instance;
