import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthService from "./services/AuthService/AuthService";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import appConfig from "./configuration/app-config.json";

import MessageBox from "./components/MessageBox/MessageBox";

const Login = lazy(() => import("./routes/Login/LoginView"));
const Home = lazy(() => import("./routes/Home/HomeView"));
const Users = lazy(() => import("./routes/Users/UsersView"));
const EditUser = lazy(() => import("./routes/EditUser/EditUserView"));
const Devices = lazy(() => import("./routes/Devices/DevicesView"));
const EditDevice = lazy(() => import("./routes/EditDevice/EditDeviceView"));
const DeviceUsers = lazy(() => import("./routes/DeviceUsers/DeviceUsersView"));
const DeviceUsersAdd = lazy(() =>
    import("./routes/DeviceUsersAdd/DeviceUsersAddView")
);
const ExperienceData = lazy(() =>
    import("./routes/ExperienceData/ExperienceDataView")
);
const Dashboard = lazy(() => import("./routes/Dashboard/DashboardView"));
const NotFound = lazy(() => import("./routes/Errors/NotFound"));
const PasswordChange = lazy(() =>
    import("./routes/PasswordChange/PasswordChange")
);
const ForgotPassword = lazy(() =>
    import("./routes/ForgotPassword/ForgotPassword")
);
const PasswordResetSent = lazy(() =>
    import("./routes/PasswordResetSent/PasswordResetSent")
);
const Analytics = lazy(() => import("./routes/Analytics/AnalyticsView"));

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: sessionStorage.user ? JSON.parse(sessionStorage.user) : null,
            showMessage: false
        };

        this.messageBox = null;
        this.messageBoxTimeout = null;
    }

    handleShowMessage = message => {
        this.messageBox = (
            <MessageBox isError={message.isError} content={message.content} />
        );

        this.hideMessageIn(appConfig.messageBoxDuration, message.isError);

        this.setState({ showMessage: true });
    };

    hideMessageIn = (timeout, isError) => {
        if (this.messageBoxTimeout !== null) {
            clearTimeout(this.messageBoxTimeout);
        }

        this.messageBoxTimeout = setTimeout(() => {
            this.setState({ showMessage: false });
            this.messageBoxTimeout = null;
        }, timeout * 1000);
    };

    handleLoggedIn = (user, accessToken) => {
        AuthService.setAccessToken(accessToken);
        AuthService.setUser(user);

        this.setState({
            user
        });
    };

    handleSignout = () => {
        window.location = "/";
        AuthService.removeAccessToken();
        delete sessionStorage.user;
    };

    render() {
        const username = this.state.user ? this.state.user.userName : "";

        return (
            <Router>
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => (
                                <Login
                                    onLoggedIn={(user, accessToken) =>
                                        this.handleLoggedIn(user, accessToken)
                                    }
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/users"
                            render={() => (
                                <Home
                                    username={username}
                                    activeEndpoint="/users"
                                    onSignoutClicked={() =>
                                        this.handleSignout()
                                    }
                                    messageBox={this.messageBox}
                                    showMessage={this.state.showMessage}
                                >
                                    <Users
                                        onMessage={message =>
                                            this.handleShowMessage(message)
                                        }
                                    />
                                </Home>
                            )}
                        />
                        <Route
                            exact
                            path="/users/create"
                            render={props => (
                                <Home
                                    username={username}
                                    activeEndpoint="/users"
                                    onSignoutClicked={() =>
                                        this.handleSignout()
                                    }
                                    messageBox={this.messageBox}
                                    showMessage={this.state.showMessage}
                                >
                                    <EditUser
                                        {...props}
                                        newUser={true}
                                        user={this.state.user}
                                        onMessage={message =>
                                            this.handleShowMessage(message)
                                        }
                                    />
                                </Home>
                            )}
                        />
                        <Route
                            exact
                            path="/users/:userId"
                            render={props => (
                                <Home
                                    username={username}
                                    activeEndpoint="/users"
                                    onSignoutClicked={() =>
                                        this.handleSignout()
                                    }
                                    messageBox={this.messageBox}
                                    showMessage={this.state.showMessage}
                                >
                                    <EditUser
                                        {...props}
                                        newUser={false}
                                        user={this.state.user}
                                        onMessage={message =>
                                            this.handleShowMessage(message)
                                        }
                                    />
                                </Home>
                            )}
                        />
                        <Route
                            exact
                            path="/devices"
                            render={() => (
                                <Home
                                    username={username}
                                    activeEndpoint="/devices"
                                    onSignoutClicked={() =>
                                        this.handleSignout()
                                    }
                                    messageBox={this.messageBox}
                                    showMessage={this.state.showMessage}
                                >
                                    <Devices
                                        onMessage={message =>
                                            this.handleShowMessage(message)
                                        }
                                    />
                                </Home>
                            )}
                        />
                        <Route
                            exact
                            path="/devices/create"
                            render={props => (
                                <Home
                                    username={username}
                                    activeEndpoint="/devices"
                                    onSignoutClicked={() =>
                                        this.handleSignout()
                                    }
                                    messageBox={this.messageBox}
                                    showMessage={this.state.showMessage}
                                >
                                    <EditDevice
                                        {...props}
                                        newDevice={true}
                                        onMessage={message =>
                                            this.handleShowMessage(message)
                                        }
                                    />
                                </Home>
                            )}
                        />
                        <Route
                            exact
                            path="/devices/:deviceId"
                            render={props => (
                                <Home
                                    username={username}
                                    activeEndpoint="/devices"
                                    onSignoutClicked={() =>
                                        this.handleSignout()
                                    }
                                    messageBox={this.messageBox}
                                    showMessage={this.state.showMessage}
                                >
                                    <EditDevice
                                        {...props}
                                        newDevice={false}
                                        onMessage={message =>
                                            this.handleShowMessage(message)
                                        }
                                    />
                                </Home>
                            )}
                        />
                        <Route
                            exact
                            path="/device-users/:deviceId"
                            render={props => (
                                <Home
                                    username={username}
                                    activeEndpoint="/devices"
                                    onSignoutClicked={() =>
                                        this.handleSignout()
                                    }
                                    messageBox={this.messageBox}
                                    showMessage={this.state.showMessage}
                                >
                                    <DeviceUsers
                                        {...props}
                                        onMessage={message =>
                                            this.handleShowMessage(message)
                                        }
                                    />
                                </Home>
                            )}
                        />
                        <Route
                            exact
                            path="/device-users/:deviceId/add"
                            render={props => (
                                <Home
                                    username={username}
                                    activeEndpoint="/devices"
                                    onSignoutClicked={() =>
                                        this.handleSignout()
                                    }
                                    messageBox={this.messageBox}
                                    showMessage={this.state.showMessage}
                                >
                                    <DeviceUsersAdd
                                        {...props}
                                        onMessage={message =>
                                            this.handleShowMessage(message)
                                        }
                                    />
                                </Home>
                            )}
                        />
                        <Route
                            exact
                            path="/experience-data/:deviceId"
                            render={props => (
                                <Home
                                    username={username}
                                    activeEndpoint=""
                                    onSignoutClicked={() =>
                                        this.handleSignout()
                                    }
                                    messageBox={this.messageBox}
                                    showMessage={this.state.showMessage}
                                >
                                    <ExperienceData
                                        {...props}
                                        onMessage={message =>
                                            this.handleShowMessage(message)
                                        }
                                    />
                                </Home>
                            )}
                        />
                        <Route
                            exact
                            path="/dashboard"
                            render={() => (
                                <Home
                                    username={username}
                                    activeEndpoint="/dashboard"
                                    onSignoutClicked={() =>
                                        this.handleSignout()
                                    }
                                    messageBox={this.messageBox}
                                    showMessage={this.state.showMessage}
                                >
                                    <Dashboard
                                        onMessage={message =>
                                            this.handleShowMessage(message)
                                        }
                                    />
                                </Home>
                            )}
                        />
                        <Route
                            exact
                            path="/user-setup/:accountActionToken"
                            render={props => (
                                <PasswordChange
                                    {...props}
                                    header="User Setup"
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/password-reset/:accountActionToken"
                            render={props => (
                                <PasswordChange
                                    {...props}
                                    header="Password Reset"
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/forgot-password"
                            render={() => <ForgotPassword />}
                        />
                        <Route
                            exact
                            path="/password-reset-sent"
                            render={() => <PasswordResetSent />}
                        />
                        <Route
                            exact
                            path="/analytics"
                            render={() => (
                                <Home
                                    username={username}
                                    activeEndpoint="/analytics"
                                    onSignoutClicked={() =>
                                        this.handleSignout()
                                    }
                                    messageBox={this.messageBox}
                                    showMessage={this.state.showMessage}
                                >
                                    <Analytics
                                        onMessage={message =>
                                            this.handleShowMessage(message)
                                        }
                                    />
                                </Home>
                            )}
                        />
                        <Route path="*" render={() => <NotFound />} />
                    </Switch>
                </Suspense>
            </Router>
        );
    }
}

export default App;
